.men_collections {
  background-color: #f4f4f6;
  padding: 40px 0px;
  margin-top: 40px;
  .men_collectionsheader {
    padding-top: 0px;
    margin-bottom: 20px;
    h2 {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-family: "Lato", sans-serif;
    }
    p {
      color: #727272;
      text-align: center;
      font-size: 18px;
      font-family: "Lato", sans-serif;
    }
  }
  .men_collections_container {
    position: relative;
    width: 100%;
    .men_jwellery_col {
      width: 100%;
    }
  }
  .from {
    font-size: 16px;
    padding-right: 15px;
  }
  .men_jwellery {
    padding: 0px;
    // background-color: #fff;
    position: relative;
    cursor: pointer;
    transition: box-shadow 0.3s;
    box-shadow: 0 3px 7px rgb(0 0 0 / 10%), 0 7px 6px rgb(0 0 0 / 12%);
    margin-bottom: 40px;
    border: 1px solid #ddd;

    span {
      width: 100% !important;
    }
    &:hover {
      box-shadow: 0px 4px 19px rgb(0 0 0 / 20%);
    }
    span {
      width: 100% !important;
    }
    img {
      width: 100% !important;
      background-color: #fff;
      padding-top: 30px;
    }
    .men_text {
      padding: 10px;

      // background: linear-gradient(
      //   345deg,
      //   #fe6774 0%,
      //   #f9d0d0 49%,
      //   #fedee0 100%
      // );
      background: transparent;
      .h3_sub {
        font-size: 18px;
        color: #000;
        text-transform: uppercase;
        margin-bottom: 0px;
        text-align: left;
        padding-top: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      p {
        color: #000;
        text-align: left;
        margin-bottom: 0 !important;
      }
      .text_color {
        color: #ff1933 !important;
        font-weight: 600;
      }
    }
  }
}
.btn_get_started {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 30px;
  padding-right: 100px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #000;
  -webkit-animation-delay: 0.8s;
  border: 1px solid #000;
  animation-delay: 0.8s;
  background: transparent;
  text-align: left;
  width: 330px;
  text-transform: uppercase;
  background-image: url("../assets/images/arrow.png");
  background-repeat: no-repeat;
  background-position: 210px 14px;
  margin-top: 5px;
  .d_block_hover {
    display: none !important;
  }
  &:hover {
    color: #fff;
    background-color: #ff1933;
    background-image: url("../assets/images/arrow-white.png");
    border: 1px solid #ff1933;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .men_collections {
    .men_jwellery {
      img {
        height: 140px !important;
        width: 100% !important;
        background-color: #fff;
        object-fit: cover;
        overflow: hidden;
        padding-top: 30px;
      }
      .men_text {
        .h3_sub {
          font-size: 16px !important;
        }
        p {
          color: #9e9e9e;
          text-align: left;
          margin-bottom: 0 !important;
          font-size: 14px;
        }
      }
    }
  }
}
