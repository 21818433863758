.featured_customers {
  width: 100%;
  position: relative;
  clear: both;
  span {
    width: 100% !important;
    img {
      width: 100% !important;
    }
  }
  .featured_customers_header {
    padding-top: 10px;
    .h2 {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-family: "Lato", sans-serif;
      margin-bottom: 0.5rem;
      line-height: 1.2;
    }
  }
  .btn_more {
    color: #ff1933;
  }
  p {
    color: #727272;
    text-align: center;
    font-size: 18px;
    font-family: "Lato", sans-serif;
    padding: 0 20px;
  }
}
