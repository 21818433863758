.sencostory {
  width: 100%;
  position: relative;
  clear: both;
  span {
    position: relative;
    width: 100% !important;
    img {
      width: 100% !important;
      object-fit: cover;
    }
  }
  .sencostory_main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    .h2 {
      font-size: 40px;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 40px;
      margin-bottom: 20px;
      padding-top: 70px;
      color: #fff;
      font-family: "newyork";
      line-height: 1.2;
    }
    .btn_get_started {
      font-family: "Raleway", sans-serif;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1px;
      display: block;
      padding: 15px 13px;
      border-radius: 4px;
      transition: 0.5s;
      line-height: 1;
      color: #fff;
      -webkit-animation-delay: 0.8s;
      animation-delay: 0.8s;
      background: #ff1933;
      text-transform: uppercase;
      border: 1px solid transparent;
      max-width: 320px;
      margin: 0 auto;
      text-align: center;
      &:hover {
        color: #fff;
      }
    }
  }
}
.history {
  width: 100%;
  position: relative;
  margin-top: -80px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;

  .h4 {
    color: #000;
    text-align: center;
    margin-bottom: 10px;
    line-height: 1.2;
  }
  p {
    color: #727272;
    font-size: 15px;
    font-weight: 400;
    padding: 0px 0px;
  }
  a {
    color: #ff1933;
    text-transform: uppercase;
    font-size: 18px;
    display: block;
    text-align: right;
  }
}
