.mobile_shop_by_category {
  width: 100%;
  padding: 30px 0px;
  position: relative;
  .shopbycategoryheader_head {
    color: #000000;
    font-weight: 600;
    font-size: 25px;
    text-align: center;
    margin: 0;
  }
  p {
    color: #555;
    text-align: center;
    font-size: 17px;
    font-family: "Lato", sans-serif;
  }
  .mobile_shop_by_category_slider {
    padding-top: 20px;
    .mobile_shop_by_category_card {
      width: 100%;
      padding: 10px 0px;
      span {
        width: 100% !important;
        position: relative !important;
      }
      img {
        width: 100% !important;
        height: auto;
      }
    }
  }
}
@media only screen and (min-width: 220px) and (max-width: 479px) {
  .mobile_shop_by_category {
    width: 100%;
    padding: 0px 0px;
    position: relative;
    h1 {
      color: #000000;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
    }
    p {
      color: #555;
      text-align: center;
      font-size: 17px;
      font-family: "Lato", sans-serif;
    }
    .mobile_shop_by_category_slider {
      padding-top: 20px;
      padding-left: 5px;
      padding-right: 5px;

      .mobile_shop_by_category_card {
        width: 100%;
        padding: 10px 0px;
        min-height: 130px;
        margin: 0 10px;
        span {
          width: 100% !important;
          position: relative !important;
        }
        img {
          width: 100% !important;
        }
      }
    }
  }
}
.swiper_button_prev_1 {
  position: absolute;
  left: -40px;
  bottom: 35%;
  font-size: 20px;
  cursor: pointer;
}
.swiper_button_next_1 {
  position: absolute;
  right: -40px;
  bottom: 35%;
  font-size: 20px;
  cursor: pointer;
}
@media only screen and (min-width: 220px) and (max-width: 1199px) {
  .swiper_button_next_1 {
    position: absolute;
    right: 12px !important;
    bottom: 35%;
    font-size: 20px;
    z-index: 9;
    cursor: pointer;
  }
  .swiper_button_prev_1 {
    position: absolute;
    left: 12px !important;
    bottom: 35%;
    font-size: 20px;
    z-index: 9;
    cursor: pointer;
  }
  .icon_color {
    p {
      font-size: 14.5px;
    }
  }
}
