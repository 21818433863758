.easy_button_conatiner {
  margin: 10px 0px 30px 0;
  .easy_button {
    width: 100%;
    background-color: #fff;
    border: 0px solid #ccc;
    box-shadow: 0 8px 15px -4px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 15px 0;
    margin-top: 20px;
    p {
      color: #4c4c4c;
      padding-top: 15px;
      margin-bottom: 0;
      font-size: 14.5px;
      min-height: 58px;
    }
    .icon1 {
      background-image: url(../assets/images/cart-btn.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      display: block;
      background-repeat: no-repeat;
    }
    .icon2 {
      background-image: url(../assets/images/ss.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      display: block;
      background-repeat: no-repeat;
    }
    .icon3 {
      background-image: url(../assets/images/card-01.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      display: block;
      background-repeat: no-repeat;
    }
    .icon4 {
      background-image: url(../assets/images/girl-01.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      display: block;
      background-repeat: no-repeat;
    }

    .icon5 {
      background-image: url(../assets/images/SILVER-ICON.png);
      background-size: 40px;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      display: block;
      background-repeat: no-repeat;
    }
    .icon6 {
      background-image: url(../assets/images/silver1.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      display: block;
      background-repeat: no-repeat;
    }
    .icon7 {
      background-image: url(../assets/images/astrology2.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      display: block;
      background-repeat: no-repeat;
    }
    .icon8 {
      background-image: url(../assets/images/video-call.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      display: block;
      background-repeat: no-repeat;
    }
    .icon9 {
      background-image: url(../assets/images/Deal-Of-The-Day.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      display: block;
      background-repeat: no-repeat;
    }
    .icon10 {
      background-image: url(../assets/images/Gift-Card.png);
      background-size: 40px 40px;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      display: block;
      background-repeat: no-repeat;
    }
    &:hover {
      background-color: #ff1933;
      border: 2px solid #ff1933;
      .icon1 {
        background-image: url(../assets/images/cart-btn_white.png);
      }
      .icon2 {
        background-image: url(../assets/images/ss1.png);
      }
      .icon3 {
        background-image: url(../assets/images/card-01_white-01-01.png);
      }
      .icon4 {
        background-image: url(../assets/images/girl-01_white-01.png);
      }
      .icon5 {
        background-image: url(../assets/images/calendar2.png);
      }
      .icon6 {
        background-image: url(../assets/images/silver.png);
      }
      .icon7 {
        background-image: url(../assets/images/astrology1.png);
      }
      .icon8 {
        background-image: url(../assets/images/video-call1.png);
      }
      .icon9 {
        background-image: url(../assets/images/Deal-Of-The-Day-1.png);
      }
      .icon10 {
        background-image: url(../assets/images/Gift-Card-1.png);
      }
      p {
        color: #fff !important;
      }
    }
  }
}
.express {
  width: 100%;
  span {
    width: 100% !important;
    width: 100% !important;
  }
}
