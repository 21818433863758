.subscribe_main {
  width: 100%;
  background-color: #ff1933;
  padding: 25px;
  .h4 {
    font-weight: 400;
    font-size: 55px !important;
    color: #fff;
    font-family: "newyork";
    margin-bottom: 0.5rem;
    line-height: 1.2;
  }
  .form_input {
    border: 0;
    border-bottom: 2px solid #fff;
    margin-bottom: 1em;
    color: #fff;
    font-size: 1em;
    padding: 0.5em 0.25rem;
    width: 100%;
    margin-right: 15px;
    background-color: transparent;
    &:focus {
      border: 0;
      outline: 0px;
      border-bottom: 2px solid #fff;
    }
  }
  .btn_get_started1 {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 14px 32px;
    border-radius: 4px;
    transition: 0.5s;
    line-height: 1;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 1px solid #fff;
    background: transparent;
    &:hover {
      color: #fff;
    }
  }
}
.form_input::-webkit-input-placeholder {
  /* Edge */
  color: #fff;
  opacity: 1;
}

.form_input::-moz-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
  opacity: 1;
}

.form_input::-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}
