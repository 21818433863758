.men_collections {
  background-color: #f4f4f6;
  padding: 50px 0px;
  .men_collectionsheader {
    padding-top: 0px;
    h2 {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-family: "Lato", sans-serif;
      padding-bottom: 15px;
    }
    p {
      color: #727272;
      text-align: center;
      font-size: 16px;
      font-family: "Lato", sans-serif;
    }
  }
  .men_collections_image {
    width: 100%;
    position: relative;
    span {
      width: 100% !important;
      img {
        width: 100% !important;
      }
    }
  }
  .men_jwellery {
    padding: 0px;
    background: transparent;
    margin-bottom: 35px;
    width: 100%;
    cursor: pointer;
    border: 1px solid #ddd;
    box-shadow: 0 0px 7px rgb(0 0 0 / 10%), 0 2px 7px rgb(0 0 0 / 12%);
    img {
      height: auto;
      width: 100%;
      background-color: #fff;
    }
    .men_text {
      padding: 10px;
      position: relative;
      background: transparent;
      .h3_sub {
        font-size: 17px;
        color: #000;
        text-transform: uppercase;
        margin-bottom: 0px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      p {
        color: #222;
        text-align: left;
        font-size: 16px;
        margin-bottom: 0 !important;
        span {
          color: #ff1933 !important;
          font-weight: 600;
          font-size: 17px;
        }
      }
    }
  }
}
.btn_get_started {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 30px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #000;
  -webkit-animation-delay: 0.8s;
  border: 1px solid #000;
  animation-delay: 0.8s;
  background: transparent;
  text-transform: uppercase;
  &:hover {
    color: #000;
  }
}
@media only screen and (min-width: 639px) and (max-width: 767px) {
  .men_collections {
    .men_jwellery {
      width: 100% !important;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 639px) {
  .btn_get_started {
    padding: 14px 14px;
    img {
      max-width: 55px !important;
    }
    &:hover {
      color: #000;
    }
  }
}
