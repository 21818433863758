.banner_main_slide {
  width: 100% !important;
  // height: 580px;
  a {
    width: 100%;
    clear: both;
    display: block;
  }
  span {
    width: 100% !important;
    position: relative;
  }
  img {
    // width: 100% !important;
    // height: 430px !important;
    // object-fit: initial !important;
  }
}
.swiper-pagination {
  bottom: 66px !important;
}

.swiper-dotted .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px) !important;
}

.swiper-dotted .swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 8px)
  ) !important;
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 8px)
  ) !important;
  display: inline-block;
  border-radius: 50%;
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}
@media only screen and (min-width: 1601px) and (max-width: 1919px) {
  .banner_main_slide {
    width: 100% !important;

    // img {
    //   height: 400px !important;
    //   object-fit: initial !important;
    // }
  }
}
@media only screen and (min-width: 1401px) and (max-width: 1600px) {
  .banner_main_slide {
    width: 100% !important;

    // img {
    //   height: 340px !important;
    //   object-fit: initial !important;
    // }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .banner_main_slide {
    width: 100% !important;

    // img {
    //   height: 310px !important;
    //   object-fit: initial !important;
    // }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner_main_slide {
    width: 100% !important;
    height: 240px !important;
    a {
      width: 100%;
      clear: both;
      display: block;
    }
    span {
      width: 100% !important;
    }
    // img {
    //   width: 100% !important;
    //   height: 240px !important;
    // }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner_main_slide {
    width: 100% !important;
    height: 190px !important;
    a {
      width: 100%;
      clear: both;
      display: block;
    }
    span {
      width: 100% !important;
    }
    img {
      width: 100% !important;
      height: 190px !important;
    }
  }
}
@media only screen and (min-width: 220px) and (max-width: 767px) {
  .banner_main_slide {
    width: 100% !important;
    max-height: 240px;
    height: auto;
    a {
      width: 100%;
      clear: both;
      display: block;
    }
    span {
      width: 100% !important;
    }
    // img {
    //   width: 100% !important;
    //   height: auto !important;
    //   object-fit: cover;
    // }
  }
}
