.sencofiveyear {
  width: 100%;
  position: relative;
  background: #ff1932;
  min-height: 600px;

  img {
    width: 100%;
    position: relative;
    bottom: 0px;
  }
  .bgimage {
    height: 600px !important;
  }
  .sencofiveyear_main {
    width: 100%;
    position: absolute;
    top: 40px;
    bottom: 40px;
    left: 0;
    right: 0px;
    h2 {
      font-size: 65px;
      text-transform: uppercase;
      font-weight: 300;
      margin-top: 40px;
      margin-bottom: 20px;
      color: #fff;
      font-family: "newyork";
    }

    .icon_box {
      display: flex;
      justify-content: space-evenly;
      text-align: center;
      margin-top: 0px;
      .first {
        position: relative;
        padding: 15px;
        min-width: 33.33%;
      }
      h4 {
        color: #fff;
      }
      .number {
        position: absolute;
        top: 70px;
        left: -126px;
        bottom: 0;
        right: 0;
        z-index: 1;
        overflow: hidden;
        font-size: 140px;
        color: rgba(43, 42, 42, 0.3);
        font-family: "newyork";
      }
      .text1 {
        color: #fff;
      }
    }
  }
}

.advantages_main {
  width: 100%;
  position: relative;
  .advantages_main_header {
    width: 100%;
  }
  .advantages_main_body {
    width: 100%;
    padding-top: 50px;
    .skv {
      width: 90%;
      margin: 0 auto;
      margin-top: -160px;
      max-width: 725px;
    }
    .card_adv_pad {
      padding: 15px 0;
    }
    .icon_adv {
      max-width: 80px !important;
      height: auto !important;
    }
    .h4 {
      color: #ff1932;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0.5rem;
      line-height: 1.2;
    }
    .h3 {
      font-family: "newyork";
      color: #ff1932;
      font-size: 60px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 0.5rem;
      line-height: 1.2;
    }
    p {
      color: #676765;
      font-size: 18px;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skv {
    width: 74% !important;
  }
}
@media only screen and (min-width: 220px) and (max-width: 767px) {
  .skv {
    width: 100% !important;
    margin: 0 auto;
    margin-top: 16px !important;
  }
}
