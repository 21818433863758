.mobile_shopbybudget {
  width: 100%;
  a {
    width: 100% !important;
    height: auto !important;
    display: block;
    clear: both;
    position: relative !important;
    background: #555;
    span {
      position: relative;
      width: 100% !important;
    }
    img {
      width: 100% !important;
      height: auto !important;
    }
    .h5 {
      position: absolute;
      left: 0;
      text-align: center;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      padding: 30px 0px;
      bottom: 0px;
      left: 0px;
      width: 100%;
      padding: 15px 20px;
      color: #fff;
      background: -moz-linear-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 1) 100%
      );
      background: -webkit-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 1) 100%
      );
      background: -webkit-linear-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 1) 100%
      );
      background: -o-linear-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 1) 100%
      );
      background: -ms-linear-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 1) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(241, 241, 241, 0) 0%,
        rgba(19, 19, 19, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 );
    }
  }
  .shopbybudgetheader {
    border-top: 1px solid #ddd;
    padding-top: 30px;
    .shopbybudgetheader_text {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-family: "Lato", sans-serif;
      line-height: 1.2;
    }
    p {
      color: #727272;
      text-align: center;
      font-size: 18px;
      font-family: "Lato", sans-serif;
    }
  }
  .row {
    --bs-gutter-x: 7px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    .col_6 {
      flex: 0 0 auto;
      width: 50%;
      padding: 3.5px 7px;
    }
  }
}
@media only screen and (min-width: 640px) and (max-width: 767px) {
  .mobile_shopbybudget {
    a {
      span {
        width: 100% !important;
        img {
          width: 100% !important;
          height: 250px !important;
          object-fit: cover;
        }
      }
    }
  }
}
@media only screen and (min-width: 480px) and (max-width: 639px) {
  .mobile_shopbybudget {
    a {
      span {
        width: 100% !important;
        img {
          width: 100% !important;
          height: 206px !important;
          object-fit: cover;
        }
      }
    }
  }
}
@media only screen and (min-width: 220px) and (max-width: 479px) {
  .mobile_shopbybudget {
    a {
      span {
        width: 100% !important;
        img {
          width: 100% !important;
          height: 156px !important;
          object-fit: cover;
        }
      }
    }
  }
}
