.lustcollection {
  width: 100%;
  padding: 0px 0px;
  // background: url(../assets/images/bg-1.png) center center no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  span {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 40px;
    color: #fff;
  }
  h2 {
    font-size: 35px;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 0px;
    color: #fff;
    font-family: "newyork";
  }
  p {
    color: #727272;
    text-align: center;
    font-size: 18px;
    font-family: "Lato", sans-serif;
  }
  .play_icon_section {
    display: flex;
    justify-content: center;
    align-self: center;
    height: 100%;
    padding-bottom: 40px;
    .play_icon1 {
      display: flex;
      justify-content: center;
      align-self: center;
      margin-left: 0px;
      i {
        font-size: 70px;
        color: #fff;
      }
    }
  }
}

.btn_get_started {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 15px 15px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #000;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background: transparent;
  text-transform: uppercase;
  border: 1px solid #000;
  margin: 30px auto;
  &:hover {
    color: #000;
  }
}

.videoResponsive {
  // overflow: hidden;
  // padding-bottom: 56.25%;
  position: relative;
  height: 430px;
}

.videoResponsive iframe {
  height: 415px;
  width: 100%;
}
@media only screen and (min-width: 640px) and (max-width: 767px) {
  .videoResponsive {
    height: auto !important;
    iframe {
      height: 270px !important;
      width: 100%;
    }
  }
}
@media only screen and (min-width: 480px) and (max-width: 639px) {
  .videoResponsive {
    height: auto !important;
    iframe {
      height: 230px !important;
      width: 100%;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .videoResponsive {
    height: auto !important;
    iframe {
      height: 210px !important;
      width: 100%;
    }
  }
}
