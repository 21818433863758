.live_banner_image {
  height: 226px;
  max-width: 1116px;
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
  a {
    overflow: hidden;
    width: 100% !important;

    span {
      max-width: 1116px !important;
      margin: 0 auto;
      display: block;
      width: 100% !important;
    }
  }
  img {
    max-width: 1116px !important;
    margin: 0 auto;
    display: block;
    width: 100% !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .custom_height_list {
    height: 400px !important;
    margin: 12px 0 !important;
  }
  .live_banner_image {
    width: 100%;
    height: 190px;
    img {
      width: 100% !important;
      object-fit: cover;
      height: 190px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .custom_height_list {
    height: 529px !important;
  }
  .live_banner_image {
    width: 100%;
    height: 140px;
    img {
      width: 100% !important;
      object-fit: cover;
      height: 140px;
    }
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .custom_height_list {
    height: 400px !important;
  }
  .live_banner_image {
    width: 100%;
    height: 92px;
    img {
      width: 100% !important;
      object-fit: cover;
      height: 92px;
    }
  }
}
@media (min-width: 480px) and (max-width: 639px) {
  .custom_height_list {
    height: 270px !important;
  }
  .live_banner_image {
    width: 100%;
    height: 92px;
    img {
      width: 100% !important;
      object-fit: cover;
      height: 92px;
    }
  }
}
@media (min-width: 320px) and (max-width: 479px) {
  .custom_height_list {
    height: 270px !important;
  }
  .live_banner_image {
    width: 100%;
    height: 58px;
    img {
      width: 100% !important;
      object-fit: cover;
      height: 58px;
    }
  }
}
