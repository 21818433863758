.vivah_collection {
  .vivah_collection_header {
    padding-top: 0px;
    border-top: 1px solid #ddd;
    padding-bottom: 10px;
    .vivah_collection_header_text {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-family: "Lato", sans-serif;
      padding-top: 30px;
    }
    p {
      color: #727272;
      text-align: center;
      font-size: 18px;
      font-family: "Lato", sans-serif;
    }
  }
  .vivah_collection_main {
    width: 100%;
    .vivah_collection_card {
      padding-bottom: 10px;
    }
    .vivah_collection_card_1 {
      padding: 0px;
      width: 100%;
      clear: both;
      margin: 10px 0;
      span {
        width: 100% !important;
      }
    }
  }
  .btn_get_started {
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 14px 30px;
    border-radius: 4px;
    transition: 0.5s;
    line-height: 1;
    color: #000;
    -webkit-animation-delay: 0.8s;
    border: 1px solid #000;
    animation-delay: 0.8s;
    background: transparent;
    text-transform: uppercase;
    margin: 30px auto;
    &:hover {
      color: #000;
    }
  }
}

.videoResponsive {
  // overflow: hidden;
  // padding-bottom: 56.25%;
  position: relative;
  height: 430px;
}

.videoResponsive article {
  height: 415px;
  width: 100%;
}
