.best_sellers {
  background-color: #fff;
  padding: 30px 0px;
  .best_sellersheader {
    padding-top: 20px;
    border-top: 1px solid #ddd;
    .h2 {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-family: "Lato", sans-serif;
    }
  }
  .jwellery {
    position: relative;
    width: 100%;
  }
  .best_sellers_main {
    width: 100%;
    padding-top: 20px;
    .best_sellers_card {
      width: 100%;
      position: relative;
      display: block;
      clear: both;
      background: linear-gradient(90deg, #ff1934 0%, #ea00b8 100%);
      span {
        width: 100% !important;
      }
      img {
        width: 100% !important;
        border: 2px solid #f5f5f5 !important;
      }
      .best_sellers_wishlist {
        position: absolute;
        right: 10px;
        top: 10px;
        button {
          background-color: transparent;
          border: 0px;
          img {
            width: 23px !important;
            border: 0px solid #f5f5f5 !important;
          }
        }
      }
      .add_to_card {
        background-color: transparent;
        border: 0px;
        float: right;
        margin: 0 10px 10px 0px;
        position: relative;
        z-index: 99;
        img {
          width: 23px !important;
          border: 0px solid #f5f5f5 !important;
        }
        i {
          font-size: 23px;
          color: #fff;
          position: relative;
        }
      }
      p {
        font-size: 18px;
        margin-bottom: 0px;
        padding-top: 5px;
        text-align: left;
        color: #fff;
        padding-left: 10px;
      }
    }
  }
}
.btn_get_started {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 4px 30px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #000;
  -webkit-animation-delay: 0.8s;
  border: 0px solid #000;
  animation-delay: 0.8s;
  background: transparent;
  float: right;
  text-transform: uppercase;
  &:hover {
    color: #000;
  }
}
.best_ss_p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  i {
    color: #fff;
    font-size: 20px;
    padding-right: 7px;
  }
}
@media only screen and (min-width: 639px) and (max-width: 767px) {
  .best_sellers {
    .jwellery {
      position: relative;
      width: 100% !important;
    }
  }
}
@media only screen and (min-width: 220px) and (max-width: 1199px) {
  .btn_get_started {
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    display: flex;
    padding: 14px 30px;
    border-radius: 4px;
    transition: 0.5s;
    line-height: 1;
    color: #000;
    -webkit-animation-delay: 0.8s;
    border: 1px solid #000;
    animation-delay: 0.8s;
    background: transparent;
    text-transform: uppercase;
    margin: 30px auto;
    max-width: 311px;
    float: none;
    span {
      display: inline-block !important;
      max-width: 90px !important;
      img {
        max-width: 90px !important;
      }
    }
  }
}
