.mobile_banner_slide {
  width: 100%;
  margin-bottom: 20px;
  .banner_images {
    width: 100%;
    span {
      width: 100% !important;
    }
    // img {
    //   width: 100% !important;
    //   height: auto !important;
    // }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mobile_banner_slide {
    width: 100%;
    margin-bottom: 20px;
    .banner_images {
      width: 100%;
      span {
        width: 100% !important;
      }
      img {
        // width: 100% !important;
        // height: 690px !important;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mobile_banner_slide {
    width: 100%;
    margin-bottom: 20px;
    .banner_images {
      width: 100%;
      span {
        width: 100% !important;
      }
      img {
        // width: 100% !important;
        // height: 690px !important;
      }
    }
  }
}
@media only screen and (min-width: 640px) and (max-width: 767px) {
  .mobile_banner_slide {
    width: 100%;
    margin-bottom: 20px;
    .banner_images {
      width: 100%;
      span {
        width: 100% !important;
      }
      img {
        // height: 670px !important;
        // object-fit: cover;
      }
    }
  }
}
@media only screen and (min-width: 480px) and (max-width: 639px) {
  .mobile_banner_slide {
    width: 100%;
    margin-bottom: 20px;
    .banner_images {
      width: 100%;
      span {
        width: 100% !important;
      }
      img {
        // height: 465px !important;
        // object-fit: fill;
      }
    }
  }
}
@media only screen and (min-width: 361px) and (max-width: 479px) {
  .mobile_banner_slide {
    width: 100%;
    margin-bottom: 20px;
    .banner_images {
      width: 100%;
      span {
        width: 100% !important;
      }
      img {
        // height: 382px !important;
        // object-fit: fill;
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 360px) {
  .mobile_banner_slide {
    width: 100%;
    margin-bottom: 20px;
    .banner_images {
      width: 100%;
      span {
        width: 100% !important;
      }
      img {
        // width: 100% !important;
        // height: 381px !important;
        // object-fit: fill;
      }
    }
  }
}
