.new_arrivals {
  background-color: #fff;
  padding: 0px 0px;
  .new_arrivalsheader {
    margin-top: 15px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
    h5 {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: left;
      text-transform: uppercase;
      width: 100%;
      text-align: center;
      font-family: "Lato", sans-serif;
    }
  }
  .jwellery {
    position: relative;
    width: 100%;
    .text {
      font-size: 13px !important;
    }
  }
  .new_arrivals_main {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 20px;
    a {
      display: block;
      width: 100%;
      clear: both;
      span {
        width: 100% !important;
      }
    }
    .new_arrivals_card {
      width: 100%;
      position: relative;

      img {
        width: 100%;
      }
      .new_arrivals_wishlist {
        position: absolute;
        right: 10px;
        top: 10px;
        button {
          background-color: transparent;
          border: 0px;
        }
      }
      .add_to_card {
        background-color: transparent;
        border: 0px;
        float: right;
      }
      p {
        font-size: 16px;
        margin-bottom: 0px;
        padding-top: 10px;
      }
    }
  }
}
.btn_get_started {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 4px 30px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #000;
  -webkit-animation-delay: 0.8s;
  border: 0px solid #000;
  animation-delay: 0.8s;
  background: transparent;
  float: right;
  text-transform: uppercase;
  &:hover {
    color: #000;
  }
}
.best_ss_p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  p {
    color: #ff1933;
    font-size: 18px;
    font-weight: 600;
  }
  i {
    color: #ff1933;
    font-size: 20px;
    padding-right: 7px;
    font-size: 23px;
  }
  button {
    background-color: transparent;
    border: 0px;
  }
}
.text_p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin: 0px;
  padding-top: 8px;
  color: #000;
  font-size: 16px;
  padding: 0 7px;
  padding-top: 7px;
  padding-bottom: 0px;
}
.card_new {
  background-color: #fbeded;
  transition: box-shadow 0.3s;
  padding-bottom: 3px;
  margin-bottom: 20px;
}
.product_image {
  position: relative;
  width: 100%;
  height: 180px;
  cursor: pointer;
}
@media only screen and (min-width: 639px) and (max-width: 767px) {
  .new_arrivals {
    .jwellery {
      position: relative;
      width: 100% !important;
    }
  }
}

@media only screen and (min-width: 220px) and (max-width: 1199px) {
  .btn_get_started {
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    display: flex;
    padding: 14px 30px;
    border-radius: 4px;
    transition: 0.5s;
    line-height: 1;
    color: #000;
    -webkit-animation-delay: 0.8s;
    border: 1px solid #000;
    animation-delay: 0.8s;
    background: transparent;
    text-transform: uppercase;
    margin: 30px auto;
    max-width: 311px;
    float: none;
    span {
      display: inline-block !important;
      max-width: 90px !important;
      img {
        max-width: 90px !important;
      }
    }
  }
}
