.trending_design {
  background-color: #fff;
  padding: 0px 0px;
  margin-top: 40px;
  .trending_design_header {
    padding-top: 30px;
    border-top: 1px solid #ddd;
    .h2 {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: left;
      text-transform: uppercase;
      width: 100%;
      font-family: "Lato", sans-serif;
    }
  }
  .trending_design_main {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    .trending_design_card {
      width: 100%;
      position: relative;
      padding: 0px;
      clear: both;
      display: block;
      width: 100%;

      img {
        width: 100%;
      }
      .trending_design_wishlist {
        position: absolute;
        right: 5px;
        top: 5px;
        button {
          background-color: transparent;
          border: 0px;
        }
      }
      .add_to_card {
        background-color: transparent;
        border: 0px;
        float: right;
      }
      p {
        font-size: 16px;
        margin-bottom: 0px;
        padding-top: 10px;
      }
    }
  }
}
.btn_get_started {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 4px 30px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #000;
  -webkit-animation-delay: 0.8s;
  border: 0px solid #000;
  animation-delay: 0.8s;
  background: transparent;
  float: right;
  text-transform: uppercase;
  &:hover {
    color: #000;
  }
}
