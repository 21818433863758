.with_every_product {
  width: 100%;
  padding: 40px 0px;
  padding-bottom: 0px;
  .h2 {
    color: #000;
    font-weight: 400;
    font-size: 35px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    line-height: 1.2;
  }
  .col_14 {
    flex: 0 0 auto;
    width: 14%;
    text-align: center;
  }
  p {
    color: #727272;
    padding-top: 15px;
    font-size: 16px;
    font-weight: 600;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .with_every_product {
    .col_14 {
      flex: 0 0 auto;
      width: 25%;
      text-align: center;
    }
  }
}
@media only screen and (min-width: 220px) and (max-width: 767px) {
  .with_every_product {
    .col_14 {
      flex: 0 0 auto;
      width: 50%;
      text-align: center;
    }
  }
}
