.featured_customers {
  .featured_customers_header {
    padding-top: 0px;
    padding-bottom: 20px;
    .h2 {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-family: "Lato", sans-serif;
      margin-bottom: 0.5rem;
      line-height: 1.2;
    }
    p {
      color: #727272;
      text-align: center;
      font-size: 18px;
      font-family: "Lato", sans-serif;
    }
  }
  .btn_more {
    color: #ff1933;
  }
  span {
    width: 100% !important;
    position: relative;
    img {
      width: 100% !important;
    }
  }
}
