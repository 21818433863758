.perfect_gift {
  background-color: #fff;
  width: 100%;
  a {
    display: block;
    clear: both;
    width: 100%;
  }
  .perfect_gift_header {
    padding-top: 0px;
    border-top: 1px solid #ddd;
    padding-bottom: 10px;
    h2 {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-family: "Lato", sans-serif;
      padding-top: 30px;
    }
    p {
      color: #727272;
      text-align: center;
      font-size: 18px;
      font-family: "Lato", sans-serif;
    }
  }
  .col_11 {
    flex: 0 0 auto;
    width: 20%;
  }
  .gift_jwellery {
    background-color: #ff1933;
    width: 100%;
    span {
      width: 100% !important;
      img {
        width: 100% !important;
        height: 468px !important;
      }
    }

    .gift_text {
      padding: 15px 0;
      text-align: center;
      .h3_sub {
        font-size: 18px;
        color: #fff;
        text-transform: uppercase;
      }
    }
    &:hover {
      background-color: #ff1933;
    }

    &:hover .h3_sub {
      color: #fff;
    }
  }
}
.btn_get_started {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 30px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #000;
  -webkit-animation-delay: 0.8s;
  border: 1px solid #000;
  animation-delay: 0.8s;
  background: transparent;
  text-transform: uppercase;
  &:hover {
    color: #000;
  }
}
.offer {
  width: 100%;
  position: relative;
  span {
    width: 100% !important;
  }
  img {
    width: 100% !important;
  }
}
@media only screen and (min-width: 220px) and (max-width: 479px) {
  .perfect_gift {
    a {
      width: 100% !important;
      clear: both;
      display: block;
    }
    .gift_jwellery {
      width: 100%;
      span {
        width: 100% !important;
        img {
          width: 100% !important;
          height: 468px !important;
        }
      }
    }
  }
}
