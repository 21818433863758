.senco_exclusive {
  width: 100%;
  padding-top: 15px;
  .senco_exclusive_header {
    padding-top: 0px;
    padding-bottom: 10px;
    .senco_exclusive_header_text {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-family: "Lato", sans-serif;
      padding-top: 15px;
    }
    p {
      color: #727272;
      text-align: center;
      font-size: 18px;
      font-family: "Lato", sans-serif;
    }
  }
  .senco_exclusive_main {
    width: 100%;
    .senco_exclusive_card {
      width: 100%;
      position: relative;
      clear: both;
      display: block;
      span {
        width: 100% !important;
      }
      img {
        width: 100%;
      }
    }
    .senco_exclusive_links {
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      padding: 5px 0px;
      background: -webkit-gradient(
        to bottom,
        rgba(19, 19, 19, 0.9) 0%,
        rgba(241, 241, 241, 0) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(19, 19, 19, 0.9) 0%,
        rgba(241, 241, 241, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#002f4b", endColorstr="#00000000",GradientType=0 );

      .btn_get_started {
        background-color: transparent;
        border: 0px;
        text-align: center;
        width: 100%;
        font-family: "Lato", sans-serif;
        font-weight: 700;
        font-size: 25px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 14px 20px;
        border-radius: 4px;
        transition: 0.5s;
        line-height: 1.5;
        color: #fff;
        -webkit-animation-delay: 0.8s;
        animation-delay: 0.8s;
        background: transparent;
        text-transform: uppercase;
        text-align: center;
        &:hover {
          color: #fff;
        }
      }
    }
  }
}
.btn_get_started1 {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 30px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #000;
  -webkit-animation-delay: 0.8s;
  border: 1px solid #000;
  animation-delay: 0.8s;
  background: transparent;
  text-transform: uppercase;
  margin: 30px auto;
  margin-bottom: 0px;
  &:hover {
    color: #000;
  }
}
